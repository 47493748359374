import React, { Fragment } from "react";
import "../styles/style-component/arrow.css";


const ArrowArray = (props) => {
 
    return (
        <Fragment>
        <button onClick={props.directions ? props.arrowArrayLeft:props.arrowArrayRight}  className={`arrow ${props.directions ? "arrowStyleRight":"arrowStyleLeft"}`} > 
        <svg className="arrowIcon" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 17 26">
          <path id="Vector" d="M10.584 12.6387L0.861328 2.91601L3.63867 0.138672L16.1387 12.6387L3.63867 25.1387L0.861328 22.3594L10.584 12.6367V12.6387Z" />
        </svg>
        </button>
        
        </Fragment>
    ); 
  }

export default ArrowArray;
