import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_17mztsr', 'template_qddr1wk', form.current, {
        publicKey: 'ntIC1LIxNUq84qcN7',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset();
      alert("Thank you!");
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name:</label>
          <input typr="text" name="from_name" placeholder="Enter your Name..."/>
          <br />
          <label>Email:</label>
          <input typr="email" name="from_email" placeholder="Enter your Email..."/>
          <br />
          <label>Phone:</label>
          <input typr="text" name="phone_number" placeholder="Enter your Phone..."/>
          <br />
          <label>Subject:</label>
          <input typr="text" name="subject" placeholder="Subject..."/>
          <br />
          <label>Message:</label>
          <textarea typr="text" name="message" placeholder="Enter your Message...">

          </textarea>
          <br />
          <button type="submit" className="sendButton" >send</button>

      {/* <input type="submit" value="Send" /> */}
    </form>
  );
};
export default ContactUs;
