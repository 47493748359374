import React, { Fragment } from "react";
import "../styles/style-component/footer.css";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import plogo from "../assets/plogo.png";

const Footer = () => {
  return (
    <Fragment>
        


      <div className="footer" >
 <br/>
        <br />

        {/* <MEPS /> */}
            <div className="footerLogo">

              <img loading="lazy"  style={{width:"130px",  height:"auto"}} src={plogo} alt="this is logo image" />
          </div>
             

          <p className={"navbarFooter"}>
            P.O.Box 191513 Office 1003, JBC5 Tower, Cluster W Jumeirah Lakes Towers, Dubai, United Arab Emirates.

            </p>

            <p className={"navbarFooter"}>
            Phone :

(+971) 42 733 799
| Email :

info@progetti.me           </p>


            
           
             <div className="footerTitle">

 <p className="footerText">Strong Foundations - High Ambitions </p>
</div>
<div className="cc">

 <p  className="footerText">Copyright© Progetti, {(new Date().getFullYear())}. All rights reserved</p>
</div>


             </div>
     </Fragment>
  );
};

export default Footer;
