import React, { Fragment } from "react";
import ImageSliderHeader from "../components/image-slider-header";
import "../styles/style-component/image-slider-header.css";
import "../styles/style-pages/home.css";
import "../styles/style-pages/form.css";
import TextBoxes from "../components/text-boxes";
import TextTitle from "../components/title-type";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../components/footer";
import about1 from "../assets/about1.jpg";
import about2 from "../assets/about2.jpg";
import about3 from "../assets/about3.jpg";
import { Link } from "react-router-dom";




import gifImg1 from "../assets/gifsImgs/gifImg (1).jpg";
import gifImg2 from "../assets/gifsImgs/gifImg (2).jpg";
import gifImg3 from "../assets/gifsImgs/gifImg (3).jpg";
import gifImg4 from "../assets/gifsImgs/gifImg (4).jpg";
import gifImg5 from "../assets/gifsImgs/gifImg (5).jpg";
import gifImg6 from "../assets/gifsImgs/gifImg (6).jpg";
import gifImg7 from "../assets/gifsImgs/gifImg (7).jpg";
import gifImg8 from "../assets/gifsImgs/gifImg (8).jpg";
import gifImg9 from "../assets/gifsImgs/gifImg (9).jpg";
import gifImg10 from "../assets/gifsImgs/gifImg (10).jpg";
import gifImg11 from "../assets/gifsImgs/gifImg (11).jpg";
import gifImg12 from "../assets/gifsImgs/gifImg (12).jpg";
import gifImg13 from "../assets/gifsImgs/gifImg (13).jpg";
import gifImg14 from "../assets/gifsImgs/gifImg (14).jpg";
import gifImg15 from "../assets/gifsImgs/gifImg (15).jpg";
import gifImg16 from "../assets/gifsImgs/gifImg (16).jpg";
import gifImg17 from "../assets/gifsImgs/gifImg (17).jpg";
import gifImg18 from "../assets/gifsImgs/gifImg (18).jpg";
import gifImg19 from "../assets/gifsImgs/gifImg (19).jpg";
import gifImg20 from "../assets/gifsImgs/gifImg (20).jpg";
import gifImg21 from "../assets/gifsImgs/gifImg (21).jpg";
import gifImg22 from "../assets/gifsImgs/gifImg (22).jpg";
import gifImg23 from "../assets/gifsImgs/gifImg (23).jpg";
import gifImg24 from "../assets/gifsImgs/gifImg (24).jpg";
import gifImg25 from "../assets/gifsImgs/gifImg (25).jpg";
import gifImg26 from "../assets/gifsImgs/gifImg (26).jpg";
import gifImg27 from "../assets/gifsImgs/gifImg (27).jpg";


import Icon1 from "../assets/Logos/icon (1).png";
import Icon2 from "../assets/Logos/icon (2).png";
import Icon3 from "../assets/Logos/icon (3).png";
import Icon4 from "../assets/Logos/icon (4).png";
import Icon5 from "../assets/Logos/icon (5).png";
import Icon6 from "../assets/Logos/icon (6).png";
import Icon7 from "../assets/Logos/icon (7).png";
import Icon8 from "../assets/Logos/icon (8).png";
import Icon9 from "../assets/Logos/icon (9).png";
import Icon10 from "../assets/Logos/icon (10).png";
import Icon11 from "../assets/Logos/icon (11).png";
import Icon12 from "../assets/Logos/icon (12).png";
import Icon13 from "../assets/Logos/icon (13).png";
import Icon14 from "../assets/Logos/icon (14).png";
import Icon15 from "../assets/Logos/icon (15).png";
import Icon16 from "../assets/Logos/icon (16).png";
import Icon17 from "../assets/Logos/icon (17).png";
import Icon18 from "../assets/Logos/icon (18).png";
import Icon19 from "../assets/Logos/icon (19).png";
import Icon20 from "../assets/Logos/icon (20).png";
import Icon21 from "../assets/Logos/icon (21).png";
import Icon22 from "../assets/Logos/icon (22).png";
import Icon23 from "../assets/Logos/icon (23).png";
import Icon24 from "../assets/Logos/icon (24).png";
import Icon25 from "../assets/Logos/icon (25).png";
import Icon26 from "../assets/Logos/icon (26).png";
import Icon27 from "../assets/Logos/icon (27).png";
import Icon28 from "../assets/Logos/icon (28).png";
import Icon29 from "../assets/Logos/icon (29).png";
import Icon30 from "../assets/Logos/icon (30).png";
import Icon31 from "../assets/Logos/icon (31).png";
import Icon32 from "../assets/Logos/icon (32).png";
import Icon33 from "../assets/Logos/icon (33).png";
import Icon34 from "../assets/Logos/icon (34).png";
import Icon35 from "../assets/Logos/icon (35).png";
import Icon36 from "../assets/Logos/icon (36).png";
import Icon37 from "../assets/Logos/icon (37).png";
import Icon38 from "../assets/Logos/icon (38).png";
import Icon39 from "../assets/Logos/icon (39).png";
import Icon40 from "../assets/Logos/icon (40).png";





import {useEffect, useState} from 'react';
import ArrowArray from "../components/arrowArray";
import ContactUs from '../components/contactus';


const aboutUsTitle = [
  {
    id: "15",

    style: "1",
    text: "About Us",
  }
];
const OurProductsTitle = [
  {
    id: "16",

    style: "2",
    text: "Our Products",
  }
];
const brands = [
  {
    id: "17",

    style: "1",
    text: "WE SUPPLY",
  }
];
const contactUsTitle = [
  {
    id: "15",

    style: "1",
    text: "Contact Us",
  }
];
const Home = () => {

  const [state, setState] = useState(1);
  const brandsLogo = [


    Icon1,
    Icon2,
    Icon3,
    Icon4,
    Icon5,
    Icon6,
    Icon7,
    Icon8,
    Icon9,
    Icon10,
    Icon11,
    Icon12,
    Icon13,
    Icon14,
    Icon15,
    Icon16,
    Icon17,
    Icon18,
    Icon19,
    Icon20,
    Icon21,
    Icon22,
    Icon23,
    Icon24,
    Icon25,
    Icon26,
    Icon27,
Icon28,
Icon29,
Icon30,
Icon31,
Icon32,
Icon33,
Icon34,
Icon35,
Icon36,
Icon37,
Icon38,
Icon39,
Icon40

  
  
  ];
  function arrowArrayRight(){
    setState((prevState)=>{
         return prevState=prevState >=brandsLogo.length ? 1: prevState+1;

    });}
    function arrowArrayLeft(){
      setState((prevState)=>{
           return prevState=prevState <= 1 ? brandsLogo.length-1: state-1;
  
      });}

      // function tttest(){
      //   Email.send({
      //     Host : "smtp.outlook.com",
      //     Username : "info@progetti.me",
      //     Password : "",
      //     To : 'info@progetti.me',
      //     From : "@gmail.com",
      //     Subject : "This is the subject",
      //     Body : "And this is the body"
      // }).then(
      //   message => alert(message)
      // );
      // }



    
      const aboutTitle1 = [
        {
          style: "3",    text: "What We Do?",
        },
      ];
      const aboutBody1 = [
        {
          style: "paragraph2",
          text: "We are specialized in sourcing           and providing high-quality           building materials to meet the           diverse needs of construction           projects, ensuring excellence in           every structure we contribute to.",
        },
      ];
      const aboutTitle2 = [
        {
          style: "3",    text: "What we offer?",
        },
      ];
      const aboutBody2 = [
        {
          style: "paragraph2",
          text: "We offer a comprehensive range of top-notch building materials, providing clients with a one-stop solution for their construction needs.",
        },
      ];
      const aboutTitle3 = [
        {
          style: "3",    text: "Our Services          ",
        },
      ];
      const aboutBody3 = [
        {
          style: "paragraph2",
          text: "Your one-stop solution for hassle-free project material procurement, providing top-quality materials and expert guidance.",
        },
      ];
  return (
    <Fragment>

      <ImageSliderHeader /> 
      <Container style={{ maxWidth: "100%" }} id="aboutUsBody">
      <br/> 
      <br/> 
      <br/> 
      <TextTitle textTitle={aboutUsTitle} />
        
       
        <Row>
        <Col lg={4} style={{padding:'0% 3% 3% 6%'}}>
        <img
                
                className="PresidentImg"
                src={about1}
                alt="What We Do"
              />
            <TextTitle textTitle={aboutTitle1} />
            <TextBoxes textParagraph={aboutBody1} /><br />
          </Col>
          <Col lg={4} style={{padding:'0% 3%  3% 3%'}}>
          <img
                className="PresidentImg"
                src={about2}
                alt="What we offer"
              />
            <TextTitle textTitle={aboutTitle2} />
            <TextBoxes textParagraph={aboutBody2} /><br />
          </Col>
          <Col lg={4} style={{padding:'0%  6%  3% 3%'}}>
          <img
                className="PresidentImg"
                src={about3}
                alt="Our Services"
              />
            <TextTitle textTitle={aboutTitle3} />
            <TextBoxes textParagraph={aboutBody3} /><br />
          </Col>
        </Row>
      </Container>


      <div className="pattern" id="ourProductsBody"></div>
      <div style={{ maxWidth: "100%" , backgroundColor:"black", padding:"20px 0 80px 0 "}}>

<Container >
<TextTitle textTitle={OurProductsTitle} />

  <Row>
   
    <Col lg={4}>
      <div className="ourProductsdivL" >
        <div className="ourProductsTitle">
      <p>
      SANITARY WARE & FITTINGS

      </p>
        </div>
      <img loading="lazy" src={gifImg4} className="ourProductsImg" />
      </div>
      <div className="ourProductsdivR" >
        <div className="ourProductsTitle">
      <p>
      DRAINS
      </p>
        </div>
      <img loading="lazy" src={gifImg14} className="ourProductsImg" />
      </div>
    </Col>

    <Col lg={8}>
    <div className="ourProductsdivR">
    <div className="ourProductsTitle">
      <p>
      VANITY UNITS & MIRRORS

      </p>
        </div>
      <img loading="lazy" src={gifImg18} className="ourProductsImg" />
</div>
    </Col>
  </Row>

  <Row>
   
   

    <Col lg={8}>
    <div className="ourProductsdivR">
    <div className="ourProductsTitle">
      <p>
      LIGHTING
      </p>
        </div>
      <img loading="lazy" src={gifImg20} className="ourProductsImg" />
</div>

    </Col>

    <Col lg={4}>
      
      <div className="ourProductsdivL" >
        <div className="ourProductsTitle">
      <p>
      SINKS & TAPS

      </p>
        </div>
      <img loading="lazy" src={gifImg15} className="ourProductsImg" />
      </div>
    </Col>
  </Row>


  <Row>
   
   <Col lg={4}>
     <div className="ourProductsdivL" >
       <div className="ourProductsTitle">
     <p>
     TILES & MARBEL

     </p>
       </div>
     <img loading="lazy" src={gifImg7} className="ourProductsImg" />
     </div>
     <div className="ourProductsdivR" >
       <div className="ourProductsTitle">
     <p>
     SOLID WOOD

     </p>
       </div>
     <img loading="lazy" src={gifImg24} className="ourProductsImg" />
     </div>
   </Col>

   <Col lg={8}>
   <div className="ourProductsdivR">
   <div className="ourProductsTitle">
     <p>
     WARDROBES, DOORS & ACCESSORIES

     </p>
       </div>
     <img loading="lazy" src={gifImg1} className="ourProductsImg" />
</div>


<div className="ourProductsdivL">
   <div className="ourProductsTitle">
     <p>
     KITCHEN & APPLIANCES

     </p>
       </div>
     <img loading="lazy" src={gifImg26} className="ourProductsImg" />
</div>
   </Col>
 </Row>
</Container>
</div>

          <div className="pattern"></div>


      <Container  id="WESUPPLYBody">
          <div className="bodyContentTexts">
       
       

        
        <Row>
          <Col  style={{padding:"2%"}} >
            
            <TextTitle textTitle={brands} />
            <br />
            <br />
          </Col>
        </Row></div>
        <div className="bodyContentTextsLogo">
        <Row style={{ marginTop:'0px'}}>
    <Col> 
      <ArrowArray  arrowArrayLeft={arrowArrayLeft} arrowArrayRight={arrowArrayRight}/>
    </Col>
     <Col>
    </Col>
    <Col> 
      <ArrowArray  directions={"."} arrowArrayLeft={arrowArrayLeft} arrowArrayRight={arrowArrayRight}/>
    </Col>
  </Row> 
        <div className="loglRow">
    <div className='logoCol'>
    <img
    className="logo1img"
    src={state >= brandsLogo.length ? brandsLogo[0]: brandsLogo[state]}
            />
    </div>
    <div lg={3}   className='logoCol'>
    <img
    className="logo2img"
    src={brandsLogo[state-1]}
            />
     </div>
    <div lg={3}   className='logoCol'>
    <img
    className="logo3img"
    src={state <= 1 ? brandsLogo[brandsLogo.length-1]: brandsLogo[state-2]}
            />
    </div>
  </div>
  <br/><br/><br/><br/><br/>
  {/* <div className="loglRow">
  
    <div lg={3}   className='logoCol'>
    <img
    className="logo2img"
    src={brandsLogo[state-3]}
            />
     </div>
    <div lg={3}   className='logoCol'>
    <img
    className="logo2img"
    src={brandsLogo[state-4]}
            />
     </div>
    <div lg={3}   className='logoCol'>
    <img
    className="logo3img"
    src={state <= 1 ? brandsLogo[brandsLogo.length-1]: brandsLogo[state-5]}
            />
    </div>
  </div> */}
      </div>
      </Container>
      
      <br/><br/><br/><br/><br/><br/>
      <button onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                     id="myBtn" title="Go to top">
      <svg className="arrowIcon" viewBox="0 0 25 16"  xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 5.55468L2.77734 15.2773L0 12.5L12.5 0L25 12.5L22.2207 15.2773L12.498 5.55468H12.5Z" fill="white"/>
</svg>

      </button>
      {/* <div className="patternw"></div> */}
      <div id="contactUsBody" style={{    }}>

      <TextTitle textTitle={contactUsTitle} />

      <div style={{width:'100%'}}>
        

        <ContactUs />
      </div>
      </div>

      {/* <script src="https://smtpjs.com/v3/smtp.js"></script> */}
     

      {/* <div className="pattern"></div> */}
<br />
<br />
<br />
      <Footer />
    </Fragment>
  );
};

export default Home;
