import React, { Fragment } from "react";
import "../styles/style-component/basic-header.css";
import { Col, Row, Container } from "react-bootstrap";
import Navbar from "./navbar";
import plogo from "../assets/plogo.png";

const HomeHeader = (props) => {
  
  return (
    <Fragment>
      
        <div
        className="headerImageSlider"
        style={{
          backgroundImage: `url(${props.backgroundImage})`
        }}
      >
<div className="iconsHeader">
  
        <div className="headerSize">
          
          {/* <QuickSearsh /> */}
            {/* <SocialIcons /> */}
          </div> </div>
          <div className="header">
 
             
             <Navbar />
             <div id="header">

             <img loading="lazy"  className="logoImg" src={plogo} alt="This is FJ logo white" />
             
            <p className="p00">BUILDING MATERIALS</p>
            {/* <p className="p00">HIGH <span style={{fontWeight: 'bold'}}>AMBITIONS</span></p> */}
             <p className="p2" >Building success, <br />
one material <br />
at a time</p>
             </div>
       
          </div>
      
      </div>
    </Fragment>
  );
};

export default HomeHeader;
