import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "../styles/style-component/navbar.css";
import ArrowArray from './arrowArray';
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      LiveNav(windowSize);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function burgerNavIcon() {
    var toggleBurgerIconButton = document.getElementById("burgerIconButton");
    toggleBurgerIconButton.classList.toggle("change");

    var headerNavbar = document.getElementById("nav");
    var headerInfo = document.getElementById("header");

    if (
      headerNavbar.style.display === "none" ||
      headerNavbar.style.display === ""
    ) {
      headerNavbar.style.display = "block";
      headerInfo.style.display = "none";
    } else {
      headerNavbar.style.display = "none";
      headerInfo.style.display = "block";
    }
  }

  return (
    <Fragment>
      <button
        id="burgerIconButton"
        className="navButton"
        onClick={burgerNavIcon}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>{" "}
      </button>
      <br />
      <nav className="navbar , justify-content-center">
        <ul className="mobileNavUl">
       
          <li>
          <AnchorLink href="#aboutUsBody">

          <Link
              className={"navbarHeader"}
            >
              About Us
            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#ourProductsBody">

          <Link
              className={"navbarHeader"}
            >
              Our Products
            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#WESUPPLYBody">

          <Link
              className={"navbarHeader"}
            >
               We Supply

            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#contactUsBody">

          <Link
              className={"navbarHeader"}
            >
              Contact Us
            </Link>            </AnchorLink>

          </li>
         
        </ul>
      </nav>
{/* 

















*/}
      <nav className="navbar , justify-content-center">
        <ul className="navUl" id="nav">
        <li>
          <AnchorLink href="#aboutUsBody">

          <Link
              className={"navbarHeader"}
            >
              About Us
            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#ourProductsBody">

          <Link
              className={"navbarHeader"}
            >
              Our Products
            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#WESUPPLYBody">

          <Link
              className={"navbarHeader"}
            >
              We Supply

            </Link>            </AnchorLink>

          </li>
          <li>
          <AnchorLink href="#contactUsBody">

          <Link
              className={"navbarHeader"}
            >
              Contact Us
            </Link>            </AnchorLink>

          </li>
         
          </ul>
      </nav>
    </Fragment>
  );
};

export default Navbar;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function LiveNav(windowSize) {
  var headerNavbar2 = document.getElementById("nav");
  var headerInfo2 = document.getElementById("header");
  headerNavbar2.style.display = "none";
  headerInfo2.style.display = "block";
}
